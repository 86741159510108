export default {
	header: {
		self: {},
		items: [
			{
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "Dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
			},
			{
				title: "Agendamento",
				root: true,
				noSubHeader: true,
				noPadding: true,
				customParentKTContentStyle: { padding: 0 },
				customKTContentStyle: { padding: 0 },
				withoutFooter: true,
				icon: "fas fa-calendar-day",
				page: "agendamento",
			},
			{
				title: 'Bar / Cozinha',
				root: true,
				icon: 'fas fa-glass-martini-alt',
				page: 'comanda-produto'
			},
			{
				title: "Gestão",
				root: true,
				icon: "fas fa-tasks",
				bullet: "dot",
				submenu: [
					{
						title: "Cliente pré aprovado",
						page: "pre-aprovado",
					},
					{
						title: "Cliente pré cancelado",
						page: "pre-cancelado",
					},
					{
						title: "Horários de feriados",
						page: "horario-feriados",
					},
					{
						title: "Clientes",
						submenu: [
							{
								title: "Lista de clientes",
								page: "cliente",
							},
							{
								title: "Cadastrar clientes",
								page: "cliente/new",
							},
							{
								title: "Clientes bloqueados",
								page: "cliente-bloqueio",
							},
							{
								title: 'Clientes recompras',
								page: 'cliente-recompra',
							},
						]
					},
				]
			},
			{
				title: "Relatórios",
				root: true,
				icon: "fas fa-chart-pie",
				bullet: "dot",
				submenu: [
					{
						title: "Financeiro",
						submenu: [
							{
								title: "Vendas",
								page: "relatorio/relatorio15",
							},
							{
								title: "Formas de pagamento",
								page: "relatorio/relatorio18"
							},
							{
								title: "Faturamento",
								page: "relatorio/relatorio20"
							},
							{
								title: "Histórico de movimentações",
								page: "relatorio/relatorio01"
							},
						]
					},
					{
						title: "Gestão",
						submenu: [
							{
								title: "Frequência do cliente",
								page: "relatorio/relatorio05"
							},
							{
								title: "Cliente por período e serviço",
								page: "relatorio/relatorio09"
							},
							{
								title: "Serviços",
								page: "relatorio/relatorio10"
							},
							{
								title: "Histórico de agendamentos",
								page: "relatorio/relatorio03"
							},
							{
								title: "Histórico de venda produtos",
								page: "relatorio/relatorio25"
							},
						]
					},
					{
						title: "Estoque",
						submenu: [
							{
								title: "Estoque atual",
								page: "relatorio/relatorio23"
							},
							{
								title: "Entrada e saída de estoque",
								page: "relatorio/relatorio12"
							},
						]
					},
					{
						title: "Profissional",
						submenu: [
							{
								title: "Ticket médio por atendimento",
								page: "relatorio/relatorio08"
							},
							{
								title: "Ticket médio de clientes distintos",
								page: "relatorio/relatorio24",
								'custom-class': "menu-wrap",
							},
							{
								title: "Comissões de serviços",
								page: "relatorio/relatorio02"
							},
							{
								title: "Comissão de produtos",
								page: "relatorio/relatorio13"
							},
						]
					},
					{
						title: "Marketing e experiência",
						submenu: [
							{
								title: "Captação de clientes",
								page: "relatorio/relatorio11"
							},
							{
								title: "Novos clientes",
								page: "relatorio/relatorio17"
							},
							{
								title: "Clientes ausentes",
								page: "relatorio/relatorio16"
							},
							{
								title: "Avaliação",
								page: "relatorio/relatorio06"
							},
							{
								title: "Aniversariantes por período",
								page: "relatorio/relatorio14"
							},
							{
								title: "Lista de espera de plano",
								page: "relatorio/relatorio29"
							},
						]
					},
					{
						title: "Assinaturas",
						submenu: [
							{
								title: "Quantidade de assinantes",
								page: "relatorio/relatorio19"
							},
							{
								title: "Cancelados por período",
								page: "relatorio/relatorio21"
							},
							{
								title: "Vendas novas por período",
								page: "relatorio/relatorio22"
							},
							{
								title: "Histórico de Transações Planos",
								page: "relatorio/relatorio04"
							},
							{
								title: "Vendas por origem",
								page: "relatorio/relatorio30"
							},
						]
					},
					{
						title: "Ticket médio",
						submenu: [
							{
								title: "Por profissional",
								page: "relatorio/relatorio26"
							},
							{
								title: "Por cliente",
								page: "relatorio/relatorio27"
							},
							{
								title: "Por atendimento",
								page: "relatorio/relatorio28"
							}
						]
					},
				]
			},
			{
				title: "Caixa",
				icon: "fas fa-cash-register",
				bullet: "dot",
				submenu: [
					{
						title: "Comandas",
						page: "comandas",
					},
					{
						title: "Caixas",
						page: "caixa",
					},
					{
						title: "Histórico de caixas",
						page: "historico-caixas",
					},
				]
			},
			{
				title: "Financeiro",
				icon: "fas fa-dollar-sign",
				bullet: "dot",
				submenu: [
					{
						title: "Comissões",
						page: "comissoes",
					},
					{
						title: "Balanço",
						page: "movimentacoes/balanco",
					},
					{
						title: "Contas a pagar",
						page: "movimentacoes/contas-a-pagar",
					},
					{
						title: "Contas a receber",
						page: "movimentacoes/contas-a-receber",
					},
					{
						title: "Criar despesa",
						page: "movimentacoes/contas-a-pagar/new",
					},
					{
						title: "Criar receita",
						page: "movimentacoes/contas-a-receber/new",
					},
					{
						title: "Cadastros",
						submenu: [
							{
								title: "Contas bancárias",
								page: "contas-bancarias",
							},
							{
								title: "Categorias Financeiras",
								page: "categorias-financeiras",
							},
							{
								title: "Formas de pagamento",
								submenu: [
									{
										title: "De comanda",
										page: "formas-pagamento/comandas",
										bullet: "dot"
									},
									{
										title: "De despesa",
										page: "formas-pagamento/despesas",
										bullet: "dot"
									}
								]
							}
						]
					},
				]
			},
			{
				title: "Minha Empresa",
				root: true,
				icon: "fas fa-building",
				bullet: "dot",
				submenu: [
					{
						title: "Dados",
						page: "perfil"
					},
					{
						title: "Estoque",
						page: "estoque"
					},
					{
						title: "Grupos de acesso",
						page: "grupo-permissao",
					},
					{
						title: "Configurações de WhatsApp",
						page: "configuracao-whatsapp"
					},
					{
						title: "Cadastros",
						submenu: [
							{
								title: "Clientes",
								page: "cliente",
							},
							{
								title: "Filiais",
								page: "filial"
							},
							{
								title: "Profissionais",
								bullet: "dot",
								submenu: [
									{
										title: "Cadastrar profissional",
										page: "usuario/new",
									},
									{
										title: "Profissional destaque",
										page: "exibicao-profissionais"
									},
									{
										title: "Listagem profissionais",
										page: "usuario",
									},
								]
							},
							{
								title: "Serviços",
								bullet: "dot",
								submenu: [
									{
										title: "Cadastrar serviços",
										page: "servico/new"
									},
									{
										title: "Exibição de serviços",
										page: "exibicao-servicos"
									},
									{
										title: "Listagem de serviços",
										page: "servico"
									},
								]
							},
							{
								title: "Banners Painel Cliente",
								page: "banners",
							},
							{
								title: "Promoções",
								bullet: "dot",
								submenu: [
									{
										title: "Serviços",
										page: "promocao/servico"
									}
								]
							},
							{
								title: "Produtos",
								page: "produtos"
							},
							{
								title: "Categorias",
								page: "categorias"
							},
							{
								title: "Planos",
								submenu: [
									{
										title: "Criar planos",
										page: "plano/new"
									},
									{
										title: "Exibição de planos",
										page: "exibicao-planos"
									},
									{
										title: "Listagem de plano",
										page: "plano"
									},
								]
							},
						]
					},
				]
			},
			// {
			// 	title: "Escola Cashbarber",
			// 	root: true,
			// 	icon: "fas fa-graduation-cap",
			// 	page: "escola-cashbarber",
			// },
			{
				title: "Clube do assinante",
				root: true,
				icon: "fas fa-ticket-alt",
				'custom-class': "menu-clube",
				bullet: "dot",
				submenu: [
					{
						title: "Empresas Parceiras",
						page: "clube/empresa-parceira",
					},
					{
						title: "Banners",
						page: "clube/banner",
					},
					{
						title: "Utilização dos cupons",
						page: "clube/utilizacao-cupons",
					},
				]
			},
			{
				title: 'D pote',
				root: true,
				icon: 'fas fa-star',
				'custom-class': "menu-dpote",
				bullet: "dot",
				submenu: [
					{
						title: "Relatório",
						page: "dpote/relatorio"
					},
					{
						title: "Históricos",
						page: "dpote/historicos"
					},
					{
						title: "Painel",
						page: "dpote/painel"
					},
				]
			},
			// Adicionando botão do Pump com comentário para teste de commit
			{
				title: "PUMP",
				'custom-class': "menu-pump",
				root: true,
				icon: "fas fa-rocket",
				page: "pump",
				bullet: "dot"
			}
		]
	}
};
